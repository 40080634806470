.main{
    width: 100%;
    height: 100%;

    position: fixed;
    left: 0%;
    box-shadow: 4px 4px 12px -6px #0002;
    border-radius: 20px;
   top: 0;
   backdrop-filter: blur(5px);
    z-index: 99;
    background-color: #0002;


    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content:  flex-start;
}
.inner{
    position: relative;
    background-color: white;
    width: 100%;
    border-radius: 1rem;
    max-width: 800px;
    min-height: 500px;
    padding: 20px 40px;

}
.close{
    position: absolute; right: 20px;
    top: 20px;
    cursor: pointer;
    transform: rotate(45deg);
}
.col{
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;align-items: center;
    align-content: center;
}
.content{
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;align-items: center;
    align-content: center;
    height: 100%;
}

.original,.book{
    flex: 1;
    display: flex;
    aspect-ratio: 1/1;
    border-radius: 20px;
    overflow: hidden;
    position: relative;

}
.warning{
    width: 100%;
    border: 1px solid #a16207;
    background-color: #fefce8;
    display: flex;
    font-size: 1rem;
   max-width: 99%;

    flex-direction: row;
    align-items: center; justify-content: center;
    border-radius: 0.5rem;
    color: #a16207;

    padding: 0.5rem 0rem;
    margin: 10px auto;
}
[lang="he"] .button[data-type="submit"] svg {
    transform: rotate(180deg);
}

.warning svg{
    margin-right: 0.3rem;
    flex-shrink: 0;
}
.warning p{
    margin: 0;
}
.spacer{
    width: 24px;
    height: 24px;
}
.original img, .book img{
    width: 100%;
    background-color: #f2f2f2;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.spacerY{
    width: 100%;
    height: 30px;
}
.label{
position: absolute;
left: 1rem;
bottom: 1rem;
background-color: white;
border-radius: 0.5rem;
padding: 10px 20px;
font-size: 1rem;
padding-top: 14px;
}

.label[data-color="yellow"]{
    background-color: #f59e0b;
}
.buttonrow{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;align-content: center;
}
.spacer_x{
    width: 1rem;
    height: 1rem;
}
.spacer_x[data-space="1"]{
    width: 0.5rem;
    height: 0.5rem;
}
.button{
    background-color: #ddd;
    color: white;
    display: flex;
    padding: 10px 20px;
    border-radius: 0.7rem;
    flex-direction: row;
    cursor: pointer;
    transition: all .2s ease-in-out;
    align-items: center;
    justify-content: center;align-content: center;
}
.button:hover{
    filter: brightness(90%);
}
.button[data-type="close"]{
    border:2px solid #4b5563 /* hsl(339.20000000000005 90.36% 51.18%) */;
    background: transparent;
    color:#4b5563/* hsl(339.20000000000005 90.36% 51.18%) */ ;
}
.button[data-type="submit"]{
    color: black;
    background:rgb(255,151,31);
    background : linear-gradient(101deg, rgba(255,151,31,1) 0%, rgba(255,169,39,1) 100%);
}
.button p{
margin: 0;
}
.confirm_message{
    margin: 5px 0;
    font-weight: 500;
    color: #6b7280;

}
.heading{
    font-size: 1.5rem;
    max-width: 80%;
}
.warning_title{
    font-weight: 800;
    margin: 10px 0 0 0;
}
.arrow_spacer{
    flex-shrink: 0;
    width: 48px;
   color:#f59e0b;
    display: flex;
    animation: poke .4s alternate-reverse infinite;
    animation-fill-mode: forwards;
    flex-direction: column;
    align-items: center;justify-content: center;align-content: center;

}
[lang="he"] .arrow_spacer svg{
    transform:rotate(180deg);
}
@keyframes poke {
    0%{
        transform: translateX(5px);
    }
    100%{
        transform: translateX(-5px);
    }

}
@media only screen and (max-width:968px) {

.arrow_spacer{

    width: 36px;



}

.arrow_spacer svg{
    width: 24px;
    height: 24px;
}
    .heading{
        font-size: 1.1rem;
        max-width: 100%;
    }
    .warning p{
        font-size: 12px;
        text-align: left;
    }
    [lang="he"] .warning p{
        text-align: center;
    }
    .spacerY{
        display: none;
    }
    .warning{
margin: 10px 2px;
    }
    .button{
        font-size: 14px;
        flex: 1;
    }
    .buttonrow{
        width: 100%;
    }

    .label{
        left: 0px;
        font-size: 12px;
        padding: 5px 10px;
        border-radius: 0 0.5rem 0.5rem 0;
        bottom: 10px;
    }
}
